<template>
  <div :id="id" :class="className" :style="styleObject" :chartData="chartData" />
</template>

<script>
import echarts from 'echarts'
import resize from "@/mirror-modules/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'bar-chart'
    },
    id: {
      type: String,
      default: 'bar-chart'
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: '874px', height: '300px' }
      }
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          hotwords: [],
          rate: []
        }
      }
    },
    chartStyle: {
      type: Object,
      default: () => {
        return {
          color: ['#31B78D', '#27B388'],
          showTitle: true,
          text: '评论热词Top10'
        }
      }
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    styleObject: function() {
      const obj = this.boxStyle
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/)
        unit = unit ? unit[0] : '%'

        obj[attr] = unit !== '%' ? this.boxStyle[attr] : this.boxStyle[attr] + unit
      }
      return obj
    }
  },
  watch: {
    chartData() {
      console.log(this.chartData)
      this.initChart()
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id))
      console.log(this.chartData)
      this.chart.setOption({
        title: {
          text: this.chartStyle.text,
          textStyle: {
            color: this.chartStyle.color[0],
            fontSize: 12
          },
          show: this.chartStyle.showTitle,
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b}:{c}',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow',
          }
        },
        grid: {
          top:'40px',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.chartData.hotwords
        },
        yAxis: {
          type: 'value',
          // interval: 25,
          splitLine: {
              show: false
          },
        },
        series: [
          {
            data: this.chartData.rate,
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal:{
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: 'rgba(39,199,125,0.00)'
                },
                {
                    offset: 1,
                    color: '#27B388'
                }
              ]),
              barBorderRadius: 5,
                label: {
                  show: false,
                  position: 'top',
                  formatter: '{c}%'
                }
              }
            },
          }
        ]
      })
    }
  }
}
</script>
