<template>
  <!-- v-loading="drawerLoading" -->
  <div style="margin: 0px; background: #fff"><!--element-loading-text="拼命加载中"-->
    <div class="headvideo">
      <div class="headvideo-img">
        <img :src="awemeDetail.aweme_cover || defultCover"/>
      </div>
      <div class="headvideo-message">
        <div class="headvideo-message-title">{{ awemeDetail.aweme_title }}</div>
        <div class="video_message">
          <span class="count_box"
          ><svg-icon
              icon-class="favorites-fill"
              style="color: #e34e3a; margin-right: 8px"
          />{{ this.common.numEdit(awemeDetail.digg_count) }}</span
          >
          <span class="count_box"
          ><svg-icon
              icon-class="comments-fill"
              style="color: #516ef3; margin-right: 8px"
          />{{ this.common.numEdit(awemeDetail.comment_count) }}</span
          >
          <span class="count_box"
          ><svg-icon
              icon-class="rate-fill"
              style="color: #f67c52; margin-right: 8px"
          />{{ this.common.numEdit(awemeDetail.share_count) }}</span
          >
        </div>
        <div class="video_hot">
          <span>视频热词：</span>
          <span
              class="hot_tag"
              v-for="(val, idx) in awemeDetail.aweme_topic"
              :key="idx"
          >
            {{ val }}
          </span>
        </div>
        <a class="play_btn" @click="handlePlay"
        >
          <svg-icon icon-class="play" style="margin-right: 8px"/>
          播放视频</a
        >
        <div class="video_time">
          <span>发布时间: {{ awemeDetail.publish_time }}</span>
          <span
          >数据更新时间:
            {{
              awemeDetail.mtime || "-"
            }}</span
          >
        </div>
      </div>
    </div>
    <div style="height: 12px; width: 100%; background: #eff6ff"></div>
    <main>
      <div class="tabs_detail">
        <div class="tabs_detail_pane">
          <a
              :class="activeName == 'videoDetail' ? 'active' : ''"
              @click="handleClickVideo('videoDetail')"
          >视频详情</a
          >
        </div>
        <div v-if="$root.dataType == 1" class="tabs_detail_pane">
          <a
              :class="activeName == 'videoAudience' ? 'active' : ''"
              @click="handleClickVideo('videoAudience')"
          >视频观众分析</a
          >
        </div>
      </div>
      <div v-show="activeName == 'videoDetail'" style="padding-top: 28px">
        <div class="detail">
          <div class="detail-message">
            <div class="img_box">
              <img
                  :style="author.avatar ? { border: '1px solid #ccc' } : ''"
                  :src="author.avatar || defultImg"
                  class="img_box"
              />
            </div>
            <div class="author_messgae">
              <p class="author_name">{{ author.nickname }}</p>
              <p class="total_fans">
                {{ this.common.numEdit(author.fans_count) }}
              </p>
            </div>
          </div>
          <div class="detail-number">
            <div class="effect_box">
              <p>平均点赞</p>
              <span>{{ this.common.numEdit(anchorAvg.avg_digg_count) }}</span>
            </div>
            <a-divider type="vertical" style="height: 60%"/>
            <div class="effect_box">
              <p>平均评论</p>
              <span>{{
                  this.common.numEdit(anchorAvg.avg_comment_count)
                }}</span>
            </div>
            <a-divider type="vertical" style="height: 60%"/>
            <div class="effect_box">
              <p>平均分享</p>
              <span>{{ this.common.numEdit(anchorAvg.avg_share_count) }}</span>
            </div>
          </div>
        </div>
        <div class="divider-title">视频播放量 <span class="text-title">展示视频发布后30天的数据</span></div>
        <div class="lineDataBtn">
          <a-radio-group v-model="group_value" button-style="solid" @change="switchingData">
            <a-radio-button value="0" style="padding: 0 12px">累计</a-radio-button>
            <a-radio-button value="1" style="padding: 0 12px">增量</a-radio-button>
          </a-radio-group>
        </div>
        <BaseCharts
            key="line"
            :style="{width:'62%',height:'400px'}"
            :option="lineData"
        />
        <div class="divider-title">视频热词Top 10</div>
        <HotwordsBar
            :id="'hotCommentTop'"
            v-show="
            awemeHotwords &&
            awemeHotwords.hotwords &&
            awemeHotwords.hotwords.length > 0
          "
            :chart-data="awemeHotwords"
            :box-style="{ width: '770px', height: '200px' }"
            :chart-style="chartStyle"
        />
        <div
            v-show="
            !awemeHotwords ||
            !awemeHotwords.hotwords ||
            awemeHotwords.hotwords.length == 0
          "
            style="
            font-size: 18px;
            margin: 40px 46px 40px 16px;
            text-align: center;
            color: #ccc;
          "
        >
          暂无数据
        </div>
        <div class="progress-all">
          <div class="progress">
            <div
                class="progress-title"
                :style="{
                color:
                  parseInt(likeOrDislike.good_rate * 100) < 50
                    ? '#4A58F3'
                    : parseInt(likeOrDislike.good_rate * 100) > 50
                    ? '#F67C52'
                    : '#57c52d',
              }"
            >
              {{
                parseInt(likeOrDislike.good_rate * 100) < 50 &&
                likeOrDislike.total !== 0
                    ? "情感偏负向"
                    : parseInt(likeOrDislike.good_rate * 100) > 50
                        ? "情感偏正向"
                        : "情感相等"
              }}
            </div>
            <div class="progress-main">
              <span style="width: 80px">正向情感</span>
              <svg-icon
                  :icon-class="'laugh'"
                  style="color: #f67c52; margin: 0 15px; font-size: 22px"
              />
              <div v-if="likeOrDislike.total" class="progress-main-div">
                <div :style="{ width: likeOrDislike.good_rate * 100 + '%' }"/>
                <span
                    :style="{
                    left:
                      'calc(' +
                      likeOrDislike.good_rate * 100 +
                      '%' +
                      ' - 16px)',
                  }"
                />
              </div>
              <div v-if="!likeOrDislike.total" class="progress-main-div">
                <div :style="{ width: '50%' }"/>
                <span :style="{ left: 'calc(' + 50 + '%' + ' - 16px)' }"/>
              </div>
              <svg-icon
                  :icon-class="'cry'"
                  style="color: #4a58f3; margin: 0 15px; font-size: 22px"
              />
              <span style="width: 80px">负向情感</span>
            </div>
            <div class="tips_rate">
              {{
                likeOrDislike.total
                    ? `${ (likeOrDislike.good_rate * 100).toFixed(1) }%`
                    : 0
              }}
            </div>
          </div>
        </div>
        <div class="divider-title">全部评论</div>
        <ul
            v-infinite-scroll="getCommentData"
            style="liststyle: none"
            infinite-scroll-disabled="disabledLoad"
            infinite-scroll-immediate="false"
            infinite-scroll-distance="0"
            class="comment_box"
        >
          <li
              v-if="commentList.length === 0"
              style="
              font-size: 18px;
              margin: 40px 0;
              text-align: center;
              color: #ccc;
            "
          >
            暂无数据
          </li>
          <li
              v-for="(val, idx) in commentList"
              :key="idx"
              class="comment_list"
              v-else
          >
            <div class="list_box">
              <span><a-avatar :src="val.fans_avatar"/></span>
              <div class="content_box">
                <span class="nickname">{{ val.fans_nickname }}</span>
                <div class="comment">{{ val.content }}</div>
                <div class="issue_time">{{ val.publish_time }}</div>
              </div>
            </div>
            <div class="icon_box">
              <svg-icon
                  :icon-class="'favorites-fill'"
                  style="color: #ff1c05; margin: 0 10px; font-size: 16px"
              />
              <div style="margin: 0 10px; color: #8f94a2">
                {{ val.digg_count }}
              </div>
            </div>
          </li>
          <p style="height: 30px; line-height: 30px">
            <span v-if="loading">加载中...</span
            ><span v-if="noMore && commentList.length > 0" style="height: 30px">没有更多了</span>
          </p>
        </ul>
      </div>
      <div v-show="activeName == 'videoAudience'" style="padding-top: 28px">
        <a-row :gutter="20">
          <a-col :span="12">
            <div class="divider-title">性别分布</div>
            <div
                v-if="!fansStat"
                style="margin: 50px 0; text-align: center; color: #ccc"
            >
              暂无数据
            </div>
            <PieBar :pie-data="fansStat" :show-title="false" v-else/>
          </a-col>
          <a-col :span="12">
            <div class="address_box">
              <div class="title">地域分布</div>
              <div class="button_group">
                <a
                    :class="isProvince ? 'primary' : ''"
                    @click="handleClickProvince"
                >省份</a
                >
                <a :class="isProvince ? '' : 'primary'" @click="handleClickCity"
                >城市</a
                >
              </div>
            </div>
            <table class="city_table_rate">
              <tr>
                <th>名称</th>
                <th>占比</th>
              </tr>
              <div
                  v-if="isProvince ? !provinceData.length : !cityData.length"
                  style="margin: 50px 0; text-align: center; color: #ccc"
              >
                暂无数据
              </div>
              <div class="boay_hidden">
                <tr
                    v-for="(item, index) in isProvince ? provinceData : cityData"
                    :key="index"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.rate }}</td>
                </tr>
              </div>
            </table>
          </a-col>
        </a-row>
      </div>
    </main>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import HotwordsBar from "./HotwordsBar";
import PieBar from "./pie";
import BaseCharts from "@/components/BaseChart";
import { merge } from "lodash";
import Api from "@/api/awemeAnalysis";


const {mapActions, mapState, mapMutations} = createNamespacedHelpers("new_video");

export default {
  components: {
    HotwordsBar,
    PieBar,
    BaseCharts
  },
  props: {
    awemeId: {
      type: String,
      required: true,
      default: undefined
    }
  },
  data() {
    return {
      isProvince: true, // 地域分布--省份，城市切换
      activeName: "videoDetail", // 视频详情，视频观众分析tab名字
      // 热词柱形图样式
      chartStyle: {
        showTitle: false,
        color: ["#31B78D", "#27B388"],
      },
      // 默认头像
      defultImg:
          "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg",
      // 默认视频图片
      defultCover:
          "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultCover.jpg",
      lineData: {
        // legend: {
        //   // show: true,
        //   orient: "horizontal",
        //   itemGap: 20
        // }, // 顶部图列标记
        tooltip: {
          trigger: "axis"
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        color: "#1785ff", // 自定义颜色
        title: {
          text: "",
          top: 0,
          left: 0
        },
        yAxis: {
          // min: 0,
          // max: (value) => {
          //   return value.max + 200;
          // },
          axisLine: {show: false}, // y轴不显示线
          type: "value",
          show: true,
          axisTick: {
            show: false
          }
        },
        series: []
      },
      group_value: "0",
      PlayVolumeTrendData: {}
    };
  },
  computed: {
    ...mapState({
      // 页面展示数据
      awemeDetail: (state) => state.awemeDetail,
      awemeHotwords: (state) => state.awemeHotwords,
      anchor: (state) => state.anchor,
      anchorAvg: (state) => state.anchorAvg,
      fansStat: (state) => state.fansStat,
      author: (state) => state.author,
      // 地域分布表格数据处理 -- 省份
      provinceData: (state) =>
          _.get(state, "fansStat.provice.percentage", []).map((val, idx) => {
            return {
              rate: `${ _.round(val * 100, 2) }%`,
              name: state.fansStat.provice.desc[idx],
            };
          }),
      // 地域分布表格数据处理 -- 城市
      cityData: (state) =>
          _.get(state, "fansStat.city.percentage", []).map((val, idx) => {
            return {
              rate: `${ _.round(val * 100, 2) }%`,
              name: state.fansStat.city.desc[idx],
            };
          }),
      // 评论部分数据 -- 开始
      commentList: (state) => _.get(state, "commentList", []),
      disabledLoad: (state) => state.loading || state.noMore,
      loading: (state) => state.loading,
      noMore: (state) => state.noMore,
      // 评论部分数据 -- 结束
      likeOrDislike: (state) => state.likeOrDislike,
      drawerLoading: (state) => state.drawerLoading,
    }),
  },
  methods: {
    ...mapActions(["initVideo", "getComment", "getVideoEmotion"]),
    ...mapMutations(["changeState"]),
    handlePlay() {
      if(this.awemeDetail.aweme_id) {
        let url;
        url = `https://www.iesdouyin.com/share/video/${ this.awemeDetail.aweme_id }/?region=CN&mid=6796480868782230792&u_code=354ddbk0h2ih&titleType=title&timestamp=1581666967&utm_campaign=client_share&app=aweme&utm_medium=ios&tt_from=copy&utm_source=copy`;
        window.open(url);
      }
    },
    handleClickProvince() {
      this.isProvince = true;
    },
    handleClickCity() {
      this.isProvince = false;
    },
    handleClickVideo(tab) {
      this.activeName = tab;
    },
    getTime(time1, time2) {
      if(time1 && !time2) {
        return time1;
      }
      if(time2 && !time1) {
        return time2;
      }
      return new Date(time1).getTime() > new Date(time2).getTime()
          ? time1
          : time2;
    },
    load() {
      this.changeState({name: "pagination", data: {}});
      this.changeState({name: "commentList", data: []});
      this.initVideo({id: this.awemeId});
      this.getPlayVolumeTrend();
      // this.getComment({id: this.awemeId})
    },
    getCommentData() {
      this.getComment({id: this.awemeId});
    },
    handleLineOptions(data, title = "") {
      // console.log(data[0]);
      this.lineData = merge({}, this.lineData, {
        xAxis: {
          data: data[0].list,
          axisLabel: {
            //x轴文字的配置
            // interval: 0,
            // rotate: '48'
            showMinLabel: true,
            showMaxLabel: true,
            interval: data.map(item => {
              return Math.ceil(item.data.length - 2);
            }),//使x轴文字显示全
          }
        },
        series: data
      });
    }, // 处理折线图
    getPlayVolumeTrend() {
      Api.getPlayVolumeTrend(this.awemeId).then(res => {
        if(res.code == 200) {
          this.PlayVolumeTrendData = res.data || {};
          const linList = [
            {
              name: "",
              type: "line",
              smooth: true,
              data: this.PlayVolumeTrendData.total.yaxis || [],
              list: this.PlayVolumeTrendData.total.xaxis || [],
            },
          ];
          this.handleLineOptions(linList, "");
        } else {
          this.$message.error(res.message || "获取数据失败");
        }
      });
    },//视频播放量数据
    switchingData() {
      let linList = [];
      switch(this.group_value) {
        case "0":
          linList = [
            {
              name: "",
              type: "line",
              smooth: true,
              data: this.PlayVolumeTrendData.total.yaxis || [],
              list: this.PlayVolumeTrendData.total.xaxis || [],
            },
          ];
          this.handleLineOptions(linList, "");
          break;
        case "1":
          linList = [
            {
              name: "",
              type: "line",
              smooth: true,
              data: this.PlayVolumeTrendData.increment.yaxis || [],
              list: this.PlayVolumeTrendData.increment.xaxis || [],
            },
          ];
          this.handleLineOptions(linList, "");
          break;
      }
    }
  },
  watch: {
    awemeId() {
      this.$nextTick(() => this.load());
    }
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    this.changeState({name: "noMore", data: false});
    this.changeState({name: "loading", data: false});
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.headvideo {
  font-size: 14px;
  color: #17233d;
  display: flex;
  padding: 44px 20px 20px 36px;

  &-img {
    height: 133px;
    width: 100px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &-message {
    &-title {
      padding-bottom: 8px;
    }

    .video_message {
      padding-bottom: 10px;

      .count_box {
        display: inline-block;
        padding-right: 48px;
      }
    }

    .video_hot {
      padding-bottom: 14px;

      .hot_tag {
        padding: 2px 16px 2px 11px;
        margin: 0 6px;
        background: rgba(74, 88, 243, 0.1);
        border-radius: 4px;
        color: #4a58f3;
      }
    }

    .play_btn {
      display: block;
      font-size: 12px;
      color: #fff;
      font-weight: bold;
      width: 107px;
      height: 36px;
      background-image: linear-gradient(180deg, #64a9f3 0%, #516ef3 100%);
      border-radius: 8px;
      text-align: center;
      line-height: 36px;
      margin-bottom: 12px;
    }

    .video_time {
      font-size: 12px;
      color: #8f94a2;

      span {
        padding-right: 18px;
      }
    }
  }
}

main {
  padding: 24px 20px 20px 36px;

  .tabs_detail {
    border-bottom: 1px solid #f1f2f5;
    padding: 0 0 10px 16px;
    display: flex;
    font-size: 16px;

    .tabs_detail_pane {
      padding-right: 68px;

      a {
        color: #8f94a2;
        padding-bottom: 8px;
      }

      .active {
        font-weight: bold;
        color: #4a58f3;
        border-bottom: 4px solid #4a58f3;
      }
    }
  }

  .divider-title {
    margin-top: 30px;
    border-left: 4px solid #4a58f3;
    padding-left: 12px;
    margin-left: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #17233d;
  }

  .detail {
    display: flex;
    justify-content: space-between;
    padding: 10px 51px 32px 16px;

    &-message {
      display: flex;
      align-items: center;

      .img_box {
        height: 62px;
        width: 62px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .author_messgae {
        font-size: 16px;
        color: #17233d;

        .author_name {
          font-weight: bold;
          padding-bottom: 5px;
        }

        .total_fans {
          padding-bottom: 5px;
        }
      }
    }

    &-number {
      display: flex;
      align-items: center;

      .effect_box {
        padding: 0 43px;
        text-align: center;

        p {
          font-size: 14px;
          color: #8f94a2;
          padding-bottom: 4px;
        }

        span {
          font-size: 20px;
          font-weight: bold;
          color: #17233d;
        }
      }
    }
  }

  .progress-all {
    padding-left: 21px;

    .progress {
      width: 80%;
      margin: 30px 0;

      .tips_rate {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
      }

      &-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
      }

      &-main {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          font-size: 14px;
          color: #999;
        }

        &-div {
          width: 450px;
          height: 10px;
          background: #4a58f3;
          position: relative;

          div {
            height: 10px;
            position: absolute;
            top: 0;
            left: 0;
            background: #f67c52;
          }

          span {
            position: absolute;
            top: 20px;
          }
        }
      }
    }
  }

  .comment_box {
    max-height: 500px;
    overflow-y: auto;
    margin: 20px 46px 20px 16px;

    .comment_list {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 10px 0 15px 0;
      border-bottom: 1px solid #f1f2f5;

      .list_box {
        flex: 12;
        display: flex;
        align-items: center;

        .emotion_icon {
          margin-right: 30px;
        }

        .content_box {
          padding-left: 16px;

          .nickname {
            display: inline-block;
            font-size: 14px;
            color: #8f94a2;
            padding-bottom: 4px;
          }

          .comment {
            font-size: 14px;
            color: #17233d;
            padding-bottom: 4px;
          }

          .issue_time {
            font-size: 12px;
            color: #8f94a2;
          }
        }
      }

      .icon_box {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        // align-self: flex-start;
      }
    }
  }
}

// .el-drawer__header {
//   margin-bottom: 0 !important;
//   padding: 10px 20px;
// }

.el-loading-spinner {
  top: 25% !important;
  margin-top: -21px;
  width: 100%;
  text-align: center;
  position: absolute;
}

.el-tabs__content {
  background-color: #fff !important;
  padding-left: 20px;
}

.el-divider--vertical {
  height: 2em;
}

.address_box {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .title {
    border-left: 4px solid #4a58f3;
    padding-left: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #17233d;
    height: 16px;
  }

  .button_group {
    display: flex;

    :first-child {
      border-radius: 4px 0 0 4px;
    }

    :last-child {
      border-radius: 0 4px 4px 0;
    }

    a:hover {
      background: rgba(74, 88, 243, 0.1);
      color: #4a58f3;
    }

    a {
      height: 26px;
      width: 51px;
      font-size: 12px;
      color: #17233d;
      text-align: center;
      line-height: 26px;
      background: #fff;
    }

    .primary {
      color: #ffffff !important;
      background: #4a58f3 !important;
    }
  }
}

.city_table_rate {
  width: 100%;
  margin: 5% auto;

  .boay_hidden {
    height: 350px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }

  tr {
    display: flex;
    width: 100%;

    th {
      background: #fafafa;
      height: 36px;
      line-height: 36px;
    }

    td {
      border-top: 1px solid #f1f2f5;
    }

    th,
    td {
      flex: 1;
      text-align: center;
      font-size: 12px;
      color: #17233d;
      height: 35px;
      line-height: 35px;
    }
  }
}

.text-title {
  font-size: 12px;
  color: #8b8e92;
  font-weight: 400;
  padding-left: 6px
}

.lineDataBtn {
  width: 60%;
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;

  //
  //span {
  //  font-size: 16px;
  //  padding-right: 10px;
  //  cursor: pointer;
  //}
}
</style>
