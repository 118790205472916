<template>
  <div :id="id" :class="className" :style="{height:height,width:width}" :pieData="pieData" />
</template>

<script>
import echarts from "echarts";
import resize from "@/mirror-modules/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "pie-chart"
    },
    id: {
      type: String,
      default: "pie-chart"
    },
    width: {
      type: String,
      default: "400px"
    },
    height: {
      type: String,
      default: "400px"
    },
    pieData: {
      type: Object,
      default: {}
    },
    showTitle:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    // this.initChart();
  },
  watch: {
    pieData() {
      this.initChart();
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));
      this.chart.setOption({
        color:['#B7BCFA','#FC9F66'],
        legend:{
          data:this.pieData.gender.desc,
          // orient: 'vertical',
          bottom: 20,
        },
        title: {
          show:this.showTitle,
          text: "性别分布",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: ({d}%)"
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "20%",
          containLabel: false
        },
        series: [
          {
            name: "性别分布",
            type: "pie",
            radius: ["0%", "50%"],
            avoidLabelOverlap: false,
            label: {
              normal:{
                position: "outside",
                alignTo: "none",
                bleedMargin: 1,
                // formatter: '{b} {d}% '
              }

            },
            emphasis: {
              label: {
                show: true,
                fontSize: "12",
                fontWeight: "bold"
              }
            },
            itemStyle: {
                borderWidth: 10,
                borderColor: '#fff'
            },
            data: [
              {
                value: this.pieData.gender.percentage[0],
                name: this.pieData.gender.desc[0]
              },
              {
                value: this.pieData.gender.percentage[1],
                name: this.pieData.gender.desc[1]
              }
            ]
          }
        ]
      });
    }
  }
};
</script>
